var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _vm.loading
        ? _c("ck-loader")
        : [
            _c("vue-headful", {
              attrs: {
                title:
                  _vm.appName +
                  " - Edit User: " +
                  _vm.user.first_name +
                  " " +
                  _vm.user.last_name
              }
            }),
            _c(
              "gov-back-link",
              {
                attrs: {
                  to: { name: "users-show", params: { user: _vm.user.id } }
                }
              },
              [_vm._v("Back to user")]
            ),
            _c(
              "gov-main-wrapper",
              [
                _c(
                  "gov-grid-row",
                  [
                    _c(
                      "gov-grid-column",
                      { attrs: { width: "one-half" } },
                      [
                        _c("gov-heading", { attrs: { size: "xl" } }, [
                          _vm._v("Users")
                        ]),
                        _c("gov-heading", { attrs: { size: "m" } }, [
                          _vm._v("Edit user")
                        ]),
                        _c("gov-body", [
                          _vm._v(
                            "Edit users who can acces the back-end of the\n            " +
                              _vm._s(_vm.appName) +
                              " service (deciding their permissions in what they\n            have access to)"
                          )
                        ]),
                        _c("user-form", {
                          attrs: {
                            errors: _vm.form.$errors,
                            first_name: _vm.form.first_name,
                            last_name: _vm.form.last_name,
                            email: _vm.form.email,
                            phone: _vm.form.phone,
                            password: _vm.form.password,
                            roles: _vm.form.roles
                          },
                          on: {
                            "update:first_name": function($event) {
                              return _vm.$set(_vm.form, "first_name", $event)
                            },
                            "update:last_name": function($event) {
                              return _vm.$set(_vm.form, "last_name", $event)
                            },
                            "update:email": function($event) {
                              return _vm.$set(_vm.form, "email", $event)
                            },
                            "update:phone": function($event) {
                              return _vm.$set(_vm.form, "phone", $event)
                            },
                            "update:password": function($event) {
                              return _vm.$set(_vm.form, "password", $event)
                            },
                            "update:roles": function($event) {
                              return _vm.$set(_vm.form, "roles", $event)
                            },
                            clear: function($event) {
                              return _vm.form.$errors.clear($event)
                            }
                          }
                        }),
                        _vm.form.$submitting
                          ? _c(
                              "gov-button",
                              { attrs: { disabled: "", type: "submit" } },
                              [_vm._v("Updating...")]
                            )
                          : _c(
                              "gov-button",
                              {
                                attrs: { type: "submit" },
                                on: { click: _vm.onSubmit }
                              },
                              [_vm._v("Update")]
                            ),
                        _vm.form.$errors.any()
                          ? _c("ck-submit-error")
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }